import React, { useEffect, useState, useContext } from "react";
import { CustomConnectTransparencyButton } from "../components/CustomConnectTransparencyButton";
import TokenomicsChart from "../components/TokenomicsChart";

const Home = () => {
  const PresaleBox = React.lazy(() => import("../components/PresaleBox"));
  const StakingBox = React.lazy(() => import("../components/StakingBox"));

  return (
    <div className="w-full flex flex-col gap-[40px] items-center bg-[#000000]">
      <div id="home" className="relative flex w-full h-[100vh]">
        <img className="absolute top-0 left-0 object-cover w-full h-full" src="images/home_bg.png" />
        <div className="absolute left-0 bottom-0 w-full h-[200px] bg-gradient-to-t from-[#000000ff] to-[#00000000]"></div>
        <div className="absolute left-0 top-0 w-full h-[200px] bg-gradient-to-b from-[#000000ff] to-[#00000000]"></div>
        <div className="w-full h-full flex flex-col gap-[40px] justify-end items-center py-[80px] z-10">
          <span className="text-[46px] md:text-[80px] leading-[96px] font-[600] text-[#ffffff] text-center md:text-left [text-shadow:_0_4px_8px_rgba(0,0,0,0.8)]">
            SHEREX
                  </span>
          <div className="w-[300px] flex">
            <CustomConnectTransparencyButton />
          </div>
          <span className="text-[20px] md:text-[24px] leading-[32px] font-[400] text-[#ffffff] text-center md:text-left [text-shadow:_0_4px_8px_rgba(0,0,0,0.8)]">
            THE MOTHER OF ALL MEME COINS
          </span>
        </div>
      </div>
      <div id="presale" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col md:flex-row gap-[20px] md:gap-[80px] justify-center px-[20px]">
          <div className="w-full md:w-1/2 flex flex-col gap-[20px] justify-between">
            <div className="flex flex-col gap-[20px]">
              <span className="text-[26px] md:text-[40px] leading-[56px] font-[600] text-white text-center md:text-left">Join the Sherex Pre-Sale</span>
              <span className="text-[14px] md:text-[32px] leading-[40px] font-[400] text-white text-center md:text-left">Secure your $SHRX tokens early and be part of the leading memecoin exchange. Our pre-sale offers exclusive rates for early supporters, giving you a stake in “The Mother of All Memecoins” before the public launch. Don’t miss out, join the future of memecoin trading today!</span>
            </div>
            <div className="flex flex-col gap-[20px]">
              <span className="text-[28px] md:text-[32px] leading-[42px] font-[400] text-white text-center md:text-left">$SHRX CONTRACT ADDRESS</span>
              <a href="https://bscscan.com/address/0x2409a6895A9e2199c379de8dA426f0683B4064B9" target="_blank" rel="noopener noreferrer" className="text-[14px] md:text-[24px] leading-[32px] font-[400] text-white text-center md:text-left hover:underline hover:font-bold">0x2409a6895A9e2199c379de8dA426f0683B4064B9</a>
            </div>
          </div>
          <div className="flex items-center w-full md:w-1/2">
            <React.Suspense fallback={<div>Loading...</div>}>
              <PresaleBox />
            </React.Suspense>
          </div>
        </div>
      </div>
      <div id="exchange" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[20px] justify-center px-[20px]">
          <span className="text-[32px] md:text-[46px] leading-[56px] font-[600] text-white text-center md:text-left">Exchange</span>
          <span className="text-[14px] md:text-[24px] leading-[32px] font-[400] text-white text-center md:text-left"> - Don't have BNB? Convert below</span>
          <iframe src="https://pancakeswap.finance/?outputCurrency=BNB&inputCurrency=0x55d398326f99059fF775485246999027B3197955" className="w-full h-[800px] border-2 border-white"></iframe>
        </div>
      </div>
      <div className="flex w-full">
        <img className="object-contain w-full" src="/images/part1.png" />
      </div>
      <div id="about" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col md:flex-row gap-[20px] md:gap-[80px] justify-center px-[20px]">
          <div className="w-full md:w-1/2 flex flex-col gap-[20px] justify-between">
            <span className="text-[32px] md:text-[46px] leading-[56px] font-[600] text-white text-center md:text-left">What is Sherex?</span>
            <span className="text-[20px] md:text-[28px] leading-[40px] font-[400] text-white text-center md:text-left">Sherex is building the ultimate platform for memecoin enthusiasts a decentralized exchange built exclusively for trading all meme tokens. Led by our powerful wolf-woman icon, “The Mother of All Memecoins,” Sherex combines ease, security, and a vibrant community, creating a seamless space where anyone can dive into memecoin trading. With diverse payment options and a mission to redefine the memecoin experience, Sherex is more than an exchange; it’s where the memecoin revolution thrives.</span>
          </div>
          <div className="flex justify-center w-full md:w-1/2">
            <img className="object-contain w-2/3" src="/images/sherex.png" />
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <img className="object-contain w-full" src="/images/part2.png" />
      </div>
      <div id="tokenomics" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[20px] px-[20px]">
          <div className="flex">
            <span className="text-[32px] md:text-[46px] leading-[56px] font-[600] text-white text-center md:text-left">Tokenomics</span>
          </div>
          <div className="flex flex-col md:flex-row gap-[40px] md:gap-[80px] justify-center">
            {/* <div className="flex w-full md:w-1/2">
              <TokenomicsBox />
            </div> */}
            <div className="flex items-center w-full">
              <TokenomicsChart />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <img className="object-contain w-full" src="/images/part3.png" />
      </div>
      <div id="roadmap" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[20px] md:gap-[80px] px-[20px] md:px-0 z-10">
          <div className="flex justify-center">
            <span className="text-[32px] md:text-[46px] leading-[56px] font-[600] text-white text-center">Roadmap 2024-2025</span>
          </div>
          <div className="hidden md:flex">
            <img className="object-contain w-full" src="/images/roadmap_landscape.png" />
          </div>
          <div className="flex md:hidden">
            <img className="object-contain w-full" src="/images/roadmap_mobile.png" />
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <img className="object-contain w-full" src="/images/part4.png" />
      </div>
      <div id="staking" className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[20px] px-[20px] z-10">
          <div className="flex flex-col">
            <span className="text-[32px] md:text-[46px] leading-[56px] font-[600] text-white text-center md:text-left">Staking</span>
            <span className="text-[20px] md:text-[24px] leading-[32px] font-[400] text-white text-center md:text-left"> - Want to make more $SHRX? Stake your $SHRX tokens and earn more!</span>
          </div>
          <React.Suspense fallback={<div>Loading...</div>}>
            <StakingBox />
          </React.Suspense>
        </div>
      </div>
      <div className="flex w-full">
        <img className="object-contain w-full" src="/images/part5.png" />
      </div>
      <div className="relative w-full flex justify-center py-[80px]">
        <div className="max-w-[1400px] w-full flex flex-col gap-[40px] md:gap-[80px] px-[20px] z-10">
          <div className="flex flex-col gap-[20px] items-center">
            <div className="flex flex-col items-center">
              <span className="text-[40px] md:text-[64px] leading-[72px] font-[600] text-white">Join Sherex</span>
              <span className="text-[40px] md:text-[64px] leading-[72px] font-[600] text-white">Community</span>
            </div>
            <span className="text-[20px] md:text-[24px] leading-[32px] font-[400] text-white"> Don't miss any $SHRX airdrop</span>
          </div>
          <div className="flex gap-[40px] md:gap-[80px] justify-center">
            <a href="https://t.me/sherexcoin" target="_blank" rel="noopener noreferrer"><img className="w-[60px] h-[60px] object-contain" src="/images/telegram.png" /></a>
            <a href="https://x.com/sherexcoin" target="_blank" rel="noopener noreferrer"><svg className="w-[50px] h-[50px]" viewBox="0 0 55 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.600728 0.248535L21.655 28.4L0.467773 51.2884H5.23616L23.7855 31.2493L38.7729 51.2884H54.9999L32.761 21.5534L52.4819 0.248535H47.7135L30.6305 18.7042L16.8277 0.248535H0.600728ZM7.61299 3.76093H15.0677L47.9865 47.7754H40.5318L7.61299 3.76093Z" fill="white" />
            </svg></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
