import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import NoPage from "./pages/NoPage";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc } from 'viem/chains';
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets, RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { metaMaskWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { PresaleStakingProvider } from "./context/presalestaking-context";

// Optimize BSC chain configuration
const optimizedBSC = {
  ...bsc,
  rpcUrls: {
    public: {
      http: [
        'https://bsc.blockpi.network/v1/rpc/public',
        'https://bsc-dataseed1.binance.org',
        'https://bsc-dataseed2.binance.org'
      ],
      webSocket: ['wss://bsc-rpc.publicnode.com']
    },
    default: {
      http: ['https://bsc.blockpi.network/v1/rpc/public']
    }
  }
};

// Configure chains with optimized public provider
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [optimizedBSC],
  [
    publicProvider({
      stallTimeout: 2000,
      priority: 1
    })
  ]
);

// WalletConnect configuration
const projectId = "99f767528ce98d086e188781651334a9";

const walletsOptions = {
  chains,
  projectId
};

// Configure wallets
const wallets = [
  metaMaskWallet({ ...walletsOptions, shimDisconnect: true }),
  walletConnectWallet({
    ...walletsOptions,
    shimDisconnect: true,
  })
];

// Create wagmi config
const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: connectorsForWallets([
    {
      groupName: "Supported Wallets",
      wallets
    }
  ])
});

// App component
function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// Root render
ReactDOM.render(
  <WagmiConfig config={config}>
    <RainbowKitProvider
      chains={chains}
      modalSize="compact"
      theme={darkTheme()}
    >
      <PresaleStakingProvider>
        <App />
      </PresaleStakingProvider>
    </RainbowKitProvider>
  </WagmiConfig>,
  document.getElementById("root")
);