import { createContext, useEffect, useState } from "react";

import { useAccount, useContractRead, useContractWrite, useContractReads, useWalletClient } from 'wagmi';

import sherexAbi from "../abis/sherex_abi.json";
import presaleAbi from "../abis/presale_abi.json";
import stakingAbi from "../abis/staking_abi.json";

import { CHAIN_INFO, SHEREX_ADDRESS, PRESALE_ADDRESS, STAKING_ADDRESS } from "../config";
import { bsc, bscTestnet } from "viem/chains";

export const sherexAddress = SHEREX_ADDRESS;
export const presaleAddress = PRESALE_ADDRESS;
export const stakingAddress = STAKING_ADDRESS;

export const chain = CHAIN_INFO.id == 56 ? bsc : bscTestnet;

function usePresaleStakingContext() {
    const { address } = useAccount();

    const { data: walletClient } = useWalletClient({ chainId: chain.id });

    const {
        isLoading: isLoadingTotalSold,
        data: totalSold,
        refetch: refetchTotalSold,
    } = useContractRead({
        abi: presaleAbi,
        address: presaleAddress,
        functionName: 'totalSold',
        args: []
    })

    const {
        isLoading: isLoadingPresalePrice,
        data: presalePrice,
    } = useContractRead({
        abi: presaleAbi,
        address: presaleAddress,
        functionName: 'PRICE',
        args: []
    })

    const {
        isLoading: isLoadingPresaleRemainingTokens,
        data: presaleRemainingTokens,
        refetch: refetchPresaleRemainingTokens,
    } = useContractRead({
        abi: presaleAbi,
        address: presaleAddress,
        functionName: 'remainingTokens',
        args: []
    })

    const {
        isLoading: isLoadingStakingRemainingTokens,
        data: stakingRemainingTokens,
        refetch: refetchStakingRemainingTokens,
    } = useContractRead({
        abi: stakingAbi,
        address: stakingAddress,
        functionName: 'remainingStakingTokens',
        args: []
    })

    const {
        isLoading: isLoadingStakingStatus,
        data: stakingStatus,
        refetch: refetchStakingStatus
    } = useContractRead({
        abi: stakingAbi,
        address: stakingAddress,
        functionName: 'checkStakeStatus',
        args: [address],
        enabled: Boolean(address),
    })

    const {
        isLoading: isLoadingAccountTokenBalance,
        data: accountTokenBalance,
        refetch: refetchAccountTokenBalance
    } = useContractRead({
        abi: sherexAbi,
        address: sherexAddress,
        functionName: 'balanceOf',
        args: [address],
        enabled: Boolean(address),
    })

    const {
        isLoading: isLoadingStakingTokenBalance,
        data: stakingTokenBalance,
        refetch: refetchStakingTokenBalance
    } = useContractRead({
        abi: sherexAbi,
        address: sherexAddress,
        functionName: 'balanceOf',
        args: [stakingAddress],
    })

    const {
        isLoading: isLoadingStakingAllowance,
        data: stakingAllowance,
        refetch: refetchStakingAllowance
    } = useContractRead({
        abi: sherexAbi,
        address: sherexAddress,
        functionName: 'allowance',
        args: [address, stakingAddress],
        enabled: Boolean(address),
    })

    const isLoading = isLoadingTotalSold || isLoadingPresalePrice || isLoadingPresaleRemainingTokens || isLoadingStakingRemainingTokens || isLoadingStakingStatus || isLoadingAccountTokenBalance || isLoadingStakingTokenBalance || isLoadingStakingAllowance;

    const executeBuy = async (args, value, from) => {
        const result = await walletClient?.writeContract({
            abi: presaleAbi,
            address: presaleAddress,
            functionName: 'buyTokens',
            args,
            from,
            value,
            gas: 1_000_000n,
        })
        return result;
    }

    const executeStake = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: stakingAbi,
            address: stakingAddress,
            functionName: 'stake',
            args,
            from,
            gas: 1_000_000n,
        })
        return result;
    }

    const executeUntake = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: stakingAbi,
            address: stakingAddress,
            functionName: 'unstake',
            args,
            from,
            gas: 1_000_000n,
        })
        return result;
    }

    const executeApprove = async (args, from) => {
        const result = await walletClient?.writeContract({
            abi: sherexAbi,
            address: sherexAddress,
            functionName: 'approve',
            args,
            from,
            gas: 1_000_000n,
        })
        return result;
    }

    useEffect(() => {

        const timerID = setInterval(() => {

            refetchTotalSold();
            refetchPresaleRemainingTokens();

            refetchStakingRemainingTokens();
            refetchStakingTokenBalance();

            if (address) {
                console.log(address);
                refetchAccountTokenBalance();

                refetchStakingStatus();
                refetchStakingAllowance(address, stakingAddress);
            }
        }, 1000 * 3);

        return () => {
            clearInterval(timerID);
        };
    }, []);

    useEffect(() => {

        refetchTotalSold();
        refetchPresaleRemainingTokens();

        refetchStakingRemainingTokens();
        refetchStakingTokenBalance();

        if (address) {
            refetchAccountTokenBalance();

            refetchStakingStatus();
            refetchStakingAllowance(address, stakingAddress);
        }
    }, [address])

    return {
        isLoading,
        totalSold,
        presalePrice,
        presaleRemainingTokens,
        stakingRemainingTokens,
        stakingStatus,
        accountTokenBalance,
        stakingTokenBalance,
        stakingAllowance,
        executeBuy,
        executeStake,
        executeUntake,
        executeApprove,
        refetchTotalSold,
        refetchPresaleRemainingTokens,
        refetchStakingRemainingTokens,
        refetchStakingTokenBalance,
        refetchStakingStatus,
        refetchStakingAllowance
    };
};

export const PresaleStakingContext = createContext();

export const PresaleStakingProvider = (props) => {
    const presaleContext = usePresaleStakingContext();

    return (
        <div>
            <PresaleStakingContext.Provider value={{ ...presaleContext }}>
                {props.children}
            </PresaleStakingContext.Provider>
        </div>
    );
};