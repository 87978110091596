export const CHAIN_INFO = { id: 56, name: "BNB Smart Chain Mainnet" };
// export const CHAIN_INFO = { id: 97, name: "BNB Smart Chain Testnet" };

export const TREASURY_ADDRESS = "0xdA4EB11E95bFe457310AA84054C92b57BD1a421B";
export const SHEREX_ADDRESS = "0x2409a6895A9e2199c379de8dA426f0683B4064B9";
export const AIRDROP_ADDRESS = "0xE6021982E04A520E81b9Ad85098803c857EE0cdd";
export const PRESALE_ADDRESS = "0x75C69E1811A6eb59aDD92925aEa4D62941973d92";
export const STAKING_ADDRESS = "0x26e5d0f0c33D1680fd479853B9cfe8Bdb0110c2d";
// export const TREASURY_ADDRESS = "0xFe062C6533C40E18Ce9Bc54add6359c92c28B8a7";
// export const SHEREX_ADDRESS = "0x40ADD59876332DE691aa58960f047E8B50A9618c";
// export const AIRDROP_ADDRESS = "0xf70a0AcC90352bE90e8Ffd7B9189AE88e590a110";
// export const PRESALE_ADDRESS = "0x8bCC45ca8c4B4cD6653327fB755241F44C1d80bA";
// export const STAKING_ADDRESS = "0xfF6432d94522c13aCdaF3627a37032472855f025";

export const PRESALE_END_TIME = 1745625600000;