import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { CustomConnectButton } from "../components/CustomConnectButton";

const Header = () => {

    const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <>
            <div className="fixed w-full flex justify-center bg-[#000000] z-20">
                <div className="max-w-[1400px] w-full flex justify-between px-[20px] py-[10px]">
                    <button onClick={(e) => { window.location.replace("/#home") }} className="text-[20px] leading-[20px] font-[400] text-white">SHEREX</button>
                    <div className="hidden md:flex gap-[40px] md:gap-[80px] items-center">
                        <div className="flex gap-[40px] items-center">
                            <button onClick={(e) => { window.location.replace("/#tokenomics") }} className="text-[14px] leading-[20px] font-[400] text-white">Tokenomics</button>
                            <button onClick={(e) => { window.location.replace("/#about") }} className="text-[14px] leading-[20px] font-[400] text-white">About</button>
                            <button onClick={(e) => { window.location.replace("/#staking") }} className="text-[14px] leading-[20px] font-[400] text-white">Staking</button>
                            <button onClick={(e) => { window.location.replace("/#roadmap") }} className="text-[14px] leading-[20px] font-[400] text-white">Roadmap</button>
                        </div>
                    </div>
                    <a href="https://drive.google.com/file/d/1taQemSW3ZTgMfH2GtSeTGcQ_3h6X355F/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="hidden md:flex text-[14px] leading-[20px] font-[400] text-white px-[20px] py-[5px] border border-[#ffffff]">WHITE PAPER</a>
                    <button onClick={(e) => { setShowMobileMenu(true); }} className="flex md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path data-name="Path 205" d="M0 0h32v32H0z" fill="none" />
                            <path data-name="Path 206" d="M3 22.852h24.074v-2.809H3zm0-7.022h24.074v-2.808H3zM3 6v2.809h24.074V6z" transform="translate(.963 1.574)" fill="#fff" />
                        </svg>
                    </button>
                </div>
            </div>
            {showMobileMenu &&
                <div className={`font-patrick fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-end items-start bg-[#ffffff30] backdrop-blur-md p-[40px] z-50`}>
                    <div
                        className="fixed inset-0 transition-opacity"
                        onClick={() => { setShowMobileMenu(false); }}
                    />
                    <div className="bg-[#00000030] backdrop-blur-sm flex flex-col border-2 border-white rounded-[10px] py-[20px] px-[10px]" >
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#tokenomics") }} className={`text-[14px] text-[#fff] border-b border-white py-[10px]`}>TOKENOMICS</button>
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#about") }} className={`text-[14px] text-[#fff] border-b border-white py-[10px]`}>ABOUT</button>
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#staking") }} className={`text-[14px] text-[#fff] border-b border-white py-[10px]`}>STAKING</button>
                        <button onClick={(e) => { setShowMobileMenu(false); window.location.replace("/#roadmap") }} className={`text-[14px] text-[#fff] border-b border-white py-[10px]`}>ROADMAP</button>
                        <a href="https://drive.google.com/file/d/1taQemSW3ZTgMfH2GtSeTGcQ_3h6X355F/view?usp=sharing" className="text-[14px] text-[#fff] border-b border-white py-[10px]">WHITE PAPER</a>
                    </div>
                </div>
            }
        </>
        /* <div className="font-helvetica absolute top-0 left-0 w-[100vw] h-[160px] flex gap-[20px] md:gap-[40px] justify-between items-center px-[10px] md:px-[60px] md:px-[120px] z-40">
            <span onClick={(e) => { navigate(`/${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className="text-[50px] font-bold text-[#000] cursor-pointer">Schöpf</span>
            <div className="hidden md:flex gap-[80px] items-center">
                <div className="flex gap-[80px]">
                    <button onClick={(e) => { navigate(`/buynow${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/buynow' ? 'font-bold' : ''} text-[#000]`}>Buynow</button>
                    <button onClick={(e) => { navigate(`/dashboard${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/dashboard' ? 'font-bold' : ''} text-[#000]`}>Dashboard</button>
                    <button onClick={(e) => { navigate(`/referral${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/referral' ? 'font-bold' : ''} text-[#000]`}>Referral</button>
                    {address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase() && <button onClick={(e) => { navigate(`/icoadmin${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[14px] ${location.pathname == '/icoadmin' ? 'font-bold' : ''} text-[#000]`}>Ico Admin</button>}
                </div>
                <CustomConnectButton />
            </div>
            <button onClick={(e) => { setShowMobileMenu(true); }} className="flex md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                    <path data-name="Path 205" d="M0 0h32v32H0z" fill="none" />
                    <path data-name="Path 206" d="M3 22.852h24.074v-2.809H3zm0-7.022h24.074v-2.808H3zM3 6v2.809h24.074V6z" transform="translate(.963 1.574)" fill="#000" />
                </svg>
            </button>
        </div >
        {showMobileMenu &&
            <div className={`font-patrick fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-end items-start bg-[#ffffff30] backdrop-blur-md p-[40px] z-50`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => { setShowMobileMenu(false); }}
                />
                <div className="bg-[#00000030] backdrop-blur-sm flex flex-col border-2 border-white rounded-[10px] py-[20px] px-[10px]" >
                    <button onClick={(e) => { setShowMobileMenu(false); navigate(`/buynow${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/buynow' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Buynow</button>
                    <button onClick={(e) => { setShowMobileMenu(false); navigate(`/dashboard${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/dashboard' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Dashboard</button>
                    <button onClick={(e) => { setShowMobileMenu(false); navigate(`/referral${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/referral' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Referral</button>
                    {address && address.toLowerCase() == ADMIN_ADDRESS.toLowerCase() && <button onClick={(e) => { setShowMobileMenu(false); navigate(`/icoadmin${presaleContext.referrer ? `?ref=${presaleContext.referrer}` : ""}`) }} className={`text-[20px] ${location.pathname == '/icoadmin' ? 'font-bold' : ''} text-[#000] border-b border-white py-[10px]`}>Ico Admin</button>}
                    <div className="flex justify-center py-[10px]">
                        <CustomConnectButton />
                    </div>
                </div>
            </div>
        } */
    );
}

export default Header;