import { useEffect, useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useDisconnect, useSwitchNetwork } from "wagmi";
import { CHAIN_INFO } from "../config";

/**
 * Custom Wagmi Connect Button (watch balance + custom design)
 */
export const CustomConnectButton = () => {
    const configuredNetwork = CHAIN_INFO;
    const { disconnect } = useDisconnect();
    const { switchNetwork } = useSwitchNetwork();

    return (
        <ConnectButton.Custom>
            {({ account, chain, openConnectModal, mounted }) => {
                const connected = mounted && account && chain;
                return (
                    <>
                        {(() => {
                            if (!connected) {
                                return (
                                    <div className="w-full flex">
                                        <button className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={openConnectModal} type="button">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.4501 13.64V14.64C21.4502 14.7709 21.3989 14.8966 21.3072 14.99C21.2156 15.0835 21.091 15.1374 20.9601 15.14H19.5001C18.9701 15.14 18.4901 14.75 18.4501 14.23C18.4201 13.92 18.5401 13.63 18.7401 13.43C18.8307 13.3362 18.9395 13.2621 19.06 13.2122C19.1804 13.1623 19.3098 13.1377 19.4401 13.14H20.9501C21.2401 13.15 21.4501 13.37 21.4501 13.64Z" fill="white" />
                                                <path d="M17.99 12.69C17.49 13.18 17.25 13.91 17.45 14.67C17.71 15.6 18.62 16.19 19.58 16.19H20.45C21 16.19 21.45 16.64 21.45 17.19V17.38C21.45 19.45 19.76 21.14 17.69 21.14H6.20995C4.13995 21.14 2.44995 19.45 2.44995 17.38V10.65C2.44995 9.42002 3.03995 8.33002 3.94995 7.65002C4.57995 7.17002 5.35995 6.89002 6.20995 6.89002H17.69C19.76 6.89002 21.45 8.58002 21.45 10.65V11.09C21.45 11.64 21 12.09 20.45 12.09H19.43C18.87 12.09 18.36 12.31 17.99 12.69ZM16.2 4.82002C16.47 5.09002 16.24 5.51002 15.86 5.51002L8.17995 5.50002C7.73995 5.50002 7.50995 4.96002 7.82995 4.65002L9.44995 3.02002C10.11 2.3666 11.0012 2.00006 11.93 2.00006C12.8587 2.00006 13.7499 2.3666 14.41 3.02002L16.16 4.79002C16.17 4.80002 16.19 4.81002 16.2 4.82002Z" fill="white" />
                                            </svg>
                                            <span className="text-[16px] leading-[26px] font-[400] text-white">Connect Wallet</span>
                                        </button>
                                    </div>
                                );
                            }

                            if (chain.unsupported || chain.id !== configuredNetwork.id) {
                                return (
                                    <div className="w-full flex">
                                        <button className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={() => switchNetwork?.(configuredNetwork.id)} type="button">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.4501 13.64V14.64C21.4502 14.7709 21.3989 14.8966 21.3072 14.99C21.2156 15.0835 21.091 15.1374 20.9601 15.14H19.5001C18.9701 15.14 18.4901 14.75 18.4501 14.23C18.4201 13.92 18.5401 13.63 18.7401 13.43C18.8307 13.3362 18.9395 13.2621 19.06 13.2122C19.1804 13.1623 19.3098 13.1377 19.4401 13.14H20.9501C21.2401 13.15 21.4501 13.37 21.4501 13.64Z" fill="white" />
                                                <path d="M17.99 12.69C17.49 13.18 17.25 13.91 17.45 14.67C17.71 15.6 18.62 16.19 19.58 16.19H20.45C21 16.19 21.45 16.64 21.45 17.19V17.38C21.45 19.45 19.76 21.14 17.69 21.14H6.20995C4.13995 21.14 2.44995 19.45 2.44995 17.38V10.65C2.44995 9.42002 3.03995 8.33002 3.94995 7.65002C4.57995 7.17002 5.35995 6.89002 6.20995 6.89002H17.69C19.76 6.89002 21.45 8.58002 21.45 10.65V11.09C21.45 11.64 21 12.09 20.45 12.09H19.43C18.87 12.09 18.36 12.31 17.99 12.69ZM16.2 4.82002C16.47 5.09002 16.24 5.51002 15.86 5.51002L8.17995 5.50002C7.73995 5.50002 7.50995 4.96002 7.82995 4.65002L9.44995 3.02002C10.11 2.3666 11.0012 2.00006 11.93 2.00006C12.8587 2.00006 13.7499 2.3666 14.41 3.02002L16.16 4.79002C16.17 4.80002 16.19 4.81002 16.2 4.82002Z" fill="white" />
                                            </svg>
                                            <span className="text-[16px] leading-[26px] font-[400] text-white whitespace-nowrap">Switch to <span className="text-[#ff8b9e]">{configuredNetwork.name}</span></span>
                                        </button>
                                    </div>
                                );
                            }

                            return (
                                <div className="w-full flex">
                                    <button className="w-full flex gap-[10px] justify-center items-center bg-[#000000] px-[20px] py-[10px] rounded-[12px] drop-shadow-lg" onClick={() => disconnect()} type="button">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.4501 13.64V14.64C21.4502 14.7709 21.3989 14.8966 21.3072 14.99C21.2156 15.0835 21.091 15.1374 20.9601 15.14H19.5001C18.9701 15.14 18.4901 14.75 18.4501 14.23C18.4201 13.92 18.5401 13.63 18.7401 13.43C18.8307 13.3362 18.9395 13.2621 19.06 13.2122C19.1804 13.1623 19.3098 13.1377 19.4401 13.14H20.9501C21.2401 13.15 21.4501 13.37 21.4501 13.64Z" fill="white" />
                                            <path d="M17.99 12.69C17.49 13.18 17.25 13.91 17.45 14.67C17.71 15.6 18.62 16.19 19.58 16.19H20.45C21 16.19 21.45 16.64 21.45 17.19V17.38C21.45 19.45 19.76 21.14 17.69 21.14H6.20995C4.13995 21.14 2.44995 19.45 2.44995 17.38V10.65C2.44995 9.42002 3.03995 8.33002 3.94995 7.65002C4.57995 7.17002 5.35995 6.89002 6.20995 6.89002H17.69C19.76 6.89002 21.45 8.58002 21.45 10.65V11.09C21.45 11.64 21 12.09 20.45 12.09H19.43C18.87 12.09 18.36 12.31 17.99 12.69ZM16.2 4.82002C16.47 5.09002 16.24 5.51002 15.86 5.51002L8.17995 5.50002C7.73995 5.50002 7.50995 4.96002 7.82995 4.65002L9.44995 3.02002C10.11 2.3666 11.0012 2.00006 11.93 2.00006C12.8587 2.00006 13.7499 2.3666 14.41 3.02002L16.16 4.79002C16.17 4.80002 16.19 4.81002 16.2 4.82002Z" fill="white" />
                                        </svg>
                                        <span className="text-[16px] leading-[26px] font-[400] text-white">Disconnect {account.displayName}</span>
                                    </button>
                                </div>
                            );
                        })()}
                    </>
                );
            }}
        </ConnectButton.Custom>
    );
};
