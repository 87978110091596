import React, { memo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const TokenomicsChart = memo(() => {
  const data = [
    { name: 'CEX', value: 32.30 },
    { name: 'LP Rewards', value: 21.00 },
    { name: 'Reserve', value: 7.00 },
    { name: 'Marketing', value: 15.00 },
    { name: 'Ecosystem & development', value: 6.00 },
    { name: 'Founders & team', value: 13.70 },
    { name: 'Pre-sale', value: 5.00 }
  ];

  const COLORS = [
    '#4B9CD3', // Light blue for Public sale
    '#336699', // Darker blue for LP Rewards
    '#392B58', // Purple for Reserve
    '#8B4B8B', // Plum for Marketing
    '#E49DA2', // Pink for Founders
    '#99CCCC', // Light blue for Pre-sale
    '#99DDDD', // Light blue for Pre-sale
  ];

  return (
    <div className="flex flex-col items-center w-full gap-8 md:flex-row">
      {/* Chart */}
      <div className="w-full md:w-2/3 h-[300px] md:h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              outerRadius="90%"
              innerRadius="65%"
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={COLORS[index % COLORS.length]} 
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* Legend */}
      <div className="grid w-full grid-cols-1 gap-3 px-4 md:w-1/3">
        {data.map((entry, index) => (
          <div key={index} className="flex items-center gap-3">
            <div 
              className="flex-shrink-0 w-4 h-4 rounded-sm" 
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            />
            <span className="text-sm text-white md:text-base">
              {`${entry.name} ${entry.value}%`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
});

export default TokenomicsChart;